export function seoDataFromPage(page) {
  const { yoast_head_json, featured_media, article_published_time } = page
  const {
    article_modified_time,
    title,
    description,
    og_title,
    og_description,
    og_type,
    og_locale,
    og_image,
    twitter_card,
    twitter_title,
    twitter_description,
    twitter_image,
  } = yoast_head_json
  return {
    title,
    description,
    og_title,
    twitter_title,
    og_description,
    twitter_description,
    og_type,
    og_locale,
    twitter_card,
    og_image,
    twitter_image,
    article_modified_time,
    article_published_time,
    featured_media,
  }
}
