import React from "react"
import { graphql } from "gatsby"

import Layout from "components/layout/layout"
import Row from "components/row"
import Heading from "components/heading"

import l from "components/layout/layout.module.scss"
import PostsGrid from "components/posts_grid"
import SEO from "components/seo"
import { seoDataFromPage } from "components/utils/data-formatting"

const ProjectenPage = ({ pageContext, data: queryData }) => {
  const { allWordpressPost, wordpressPage } = queryData
  const projects = allWordpressPost.edges
  const pageTitle = pageContext.title

  const meta = seoDataFromPage(wordpressPage)

  return (
    <Layout>
      <SEO title={pageTitle} meta={meta} />
      <Row customClass={l.archive_title_section}>
        <Heading type="h1" color="white">
          {pageTitle}
        </Heading>
      </Row>
      <PostsGrid posts={projects} />
    </Layout>
  )
}

export default ProjectenPage

// add page query
export const pageQuery = graphql`
  query($wordpress_id: Int!) {
    allWordpressPost(sort: { order: DESC, fields: date }) {
      edges {
        node {
          title
          featured_media {
            ...ImageDefault
          }
          slug
        }
      }
    }
    wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      ...SEOData
    }
  }
`
