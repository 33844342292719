import React from "react"
import { Link } from "gatsby"

import Row from "./row"
import Image from "safe-gatsby-image-plugin"

import p from "./posts_grid.module.scss"

const PostsGrid = ({ posts }) => {
  // Split project array into chunks of 4
  let chunkedPostsArr = []
  for (let i = 0; i < posts.length; i += 4) {
    chunkedPostsArr.push(posts.slice(i, i + 4))
  }

  const renderCardContent = post => (
    <>
      <Image className={p.image} data={post.featured_media} />
      <div className={p.title}>{post.title}</div>
    </>
  )

  return (
    <Row>
      <div className={p.posts_grid}>
        {chunkedPostsArr.map((arr, index) => {
          return (
            <div
              key={index}
              className={[p.inner, index % 2 !== 0 && p.alternate].join(" ")}
            >
              {arr.map(({ node }, postItemIndex) => {
                if (node.file) {
                  return (
                    <a
                      key={`${node.title}-${postItemIndex}`}
                      rel="noreferrer"
                      className={[
                        p.post_item,
                        p[`post_item_${postItemIndex + 1}`],
                      ].join(" ")}
                      target="_blank"
                      href={node.file.url.localFile.publicURL}
                    >
                      {renderCardContent(node)}
                    </a>
                  )
                } else {
                  return (
                    <Link
                      key={`${node.title}-${postItemIndex}`}
                      to={node.slug}
                      className={[
                        p.post_item,
                        p[`post_item_${postItemIndex + 1}`],
                      ].join(" ")}
                    >
                      {renderCardContent(node)}
                    </Link>
                  )
                }
              })}
            </div>
          )
        })}
      </div>
    </Row>
  )
}

export default PostsGrid
